import React from "react"
import Layout from "../template/index"
import SEO from "../utils/seo"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10vw;
`

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Wrapper>
      <h3>GECKO Governance Privacy Statement</h3>
      At GECKO Governance, we respect the right to personal privacy and our
      obligations under the General Data Protection Regulation (GDPR), which
      comes into force across the EU on 25 May 2018. This Privacy Policy
      outlines how we deal with any personal data provided to us while visiting
      the GECKO Governance website (‘the website’), or as a registered user. By
      using this website, you are accepting the terms of this privacy statement.
      Any external links to other websites are clearly identifiable as such, and
      we are not responsible for the content or the privacy statements of these
      other websites.
      <h3>Types of Information Collected</h3>
      We retain two types of information:
      <br />
      <b>Personal Data</b> This is data that identifies you or can be used to
      identify or contact you and includes information such as your name,
      address, email address, and place of work.
      <br />
      <b>Non-Personal Data</b> Currently, the website gathers statistical and
      other analytical information collected on an aggregate basis of all
      visitors to our website. This Non-Personal Data is information that cannot
      be used to identify or contact you, such as user IP addresses (where they
      have been clipped), browser types and other anonymous statistical data
      involving the use of our website.
      <br />
      <br />
      The website uses Google Analytics, a web analytics service provided by
      Google, Inc. ("Google"). Google Analytics uses "cookies" (text files
      placed on your computer), in order to analyze how users use the site. The
      information generated by the cookie about your use of the website
      (including your IP address) will be transmitted to and stored by, Google
      on servers in the United States . Google will use this information for the
      purpose of evaluating your use of the website, compiling reports on
      website activity for website operators and providing other services
      relating to website activity and internet usage. Google may also transfer
      this information to third parties where required to do so by law, or where
      such third parties process the information on Google's behalf.
      <br />
      Google will not associate your IP address with any other data held by
      Google. You may refuse the use of cookies by selecting the appropriate
      settings on your browser, however, please note that if you do this you may
      not be able to use the full functionality of this website. By using this
      website and clicking on the 'I Accept' button, you consent to the
      processing of data about you by Google in the manner and for the purposes
      set out above.
      <h3>Purposes for which we hold your Information</h3>
      <b>Non-Personal Data</b> We use the Non-Personal Data gathered from
      visitors to our website in an aggregate form to get a better understanding
      of where our visitors come from and to help us better design and organise
      our website.
      <br />
      <b>Cookies</b> The website uses "cookie" technology. We may use cookies to
      deliver content specific to your interests and to save your personal
      preferences so you do not have to re-enter them each time you connect to
      our website - our cookies are not available to other websites. Our cookies
      will record the number of user sessions on the site and to track the
      number of users who return to the site. You are always free to decline our
      cookies, if your browser permits, or to ask your browser to indicate when
      a cookie is being sent. You can also delete cookie files from your
      computer at your discretion. Note that if you decline our cookies or ask
      for notification each time a cookie is being sent, this may affect your
      ease of use of the website.
      <br />
      <b>Personal Data</b> We will process any Personal Data you provide to us
      for the following purposes:
      <br />
      To respond to any queries you submit to us <br />
      To seek your views on our site and our services <br />
      To send you newsletters or other forms of communication that you register
      for <br />
      <h3>Disclosure of Information to Third Parties</h3>
      We will not disclose your Personal Data to third parties unless you have
      consented to this disclosure or unless the third party is required to
      fulfil a service you have requested (in such circumstances, the third
      party is bound by similar data protection requirements). Any third party
      we disclose the information to will use it for the purposes of delivering
      the service in question unless you have agreed otherwise. We will disclose
      your Personal Data if we believe in good faith that we are required to
      disclose it in order to comply with any applicable law, a summons, a
      search warrant, a court or regulatory order, or other statutory
      requirement.
      <h3>Security</h3>
      Your Personal Data is held on secure servers hosted by our Internet
      Service Provider. The nature of the Internet is such that we cannot
      guarantee or warrant the security of any information you transmit to us
      via the Internet. No data transmission over the Internet can be guaranteed
      to be 100% secure. However, we will take all reasonable steps (including
      appropriate technical and organisational measures) to protect your
      Personal Data.
      <h3>Updating, Verifying and Deleting Personal Data</h3>
      You may inform us of any changes in your Personal Data, and in accordance
      with our obligations under the General Data Protection Regulation (GDPR)
      we will update or delete your Personal Data accordingly. We will not hold
      your Personal Data for any longer than is necessary for functions for our
      website. We will review our databases on a periodic basis, and shall
      delete data that is outdated, inaccurate, or no longer necessary. GECKO
      Governance reserves the right to contact users in this regard.
      <h3>Changes to the Website Privacy Policy</h3>
      Any changes to this Website Privacy Statement will be posted on this
      website so you are always aware of what information we collect, how we use
      it, and under what circumstances, if any, we disclose it. If at any time
      we decide to use Personal Data in a manner significantly different from
      that stated in this Website Privacy Statement, or otherwise disclosed to
      you at the time it was collected, we will notify you by email, and you
      will have a choice as to whether or not we use your information in the new
      manner.
      <br />
      Any queries or comments on this policy should be sent to:
      michelle&#64;geckogovernance.com
      <br />
      <br />
      <br />
    </Wrapper>
  </Layout>
)

export default PrivacyPolicy
